import PropTypes from 'prop-types';
import React from 'react';
import Box from '../../base/Box';
import Img from '../Img';

const Banner = ({ sx, children, bgImage, image, imageSx, imageBgSx }) => {
  const bannerSx = {
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    position: 'relative',
    ...sx,
  };

  const ImageBgStyle = image
    ? {
        textAlign: 'center',
        ...imageBgSx,
      }
    : {
        bg: 'grays.9',
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
        ...imageBgSx,
      };

  const ImageStyle = image
    ? {
        ...imageSx,
      }
    : {
        backgroundImage: `url('${bgImage}')`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100%',
        left: 0,
        opacity: '.8',
        position: 'absolute',
        top: 0,
        width: '100%',
        ...imageSx,
      };

  return (
    <Box sx={bannerSx}>
      <Box sx={ImageBgStyle}>
        {image ? (
          <Img alt="" as="img" src={image} sx={ImageStyle} />
        ) : (
          <Box sx={ImageStyle} />
        )}
      </Box>
      {children}
    </Box>
  );
};

Banner.propTypes = {
  bgImage: PropTypes.string,
  children: PropTypes.node,
  image: PropTypes.string,
  imageBgSx: PropTypes.shape({}),
  imageSx: PropTypes.shape({}),
  sx: PropTypes.shape({}),
};

Banner.defaultProps = {
  bgImage: '',
  children: null,
  image: '',
  imageBgSx: {},
  imageSx: {},
  sx: {},
};

export default Banner;
